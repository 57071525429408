import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import { RenderBlock } from "components/RenderBlock";
import {
  contactUsCard,
  isNavbar,
  isBanner,
  isContactUsCard,
  isWeddingPricing,
  weddingPricing,
  recommendation,
  isRecommendation,
  isAnnouncementStrip,
} from "utils/renderBlocks";
import { Grid, Container } from "components/Design/Grid/Grid";
import Icon from "components/CommonIcon/Icon";
import { Pricing, PricingItem } from "./Design/Card/Pricing";

export const WeddingPackageLayout = ({ blocks, weddingPackage }) => {
  const { t } = useTranslation("homepage");
  const contactUsBlock = contactUsCard(blocks);
  const recommendations = recommendation(blocks);
  const pricingBlock = weddingPricing(blocks);
  let nonPickedBlocks = blocks.filter(
    (b) =>
      !isNavbar(b) &&
      !isBanner(b) &&
      !isWeddingPricing(b) &&
      !isAnnouncementStrip(b)
  );

  const [weddingVenue] = weddingPackage.wedding_venues;

  const hideInDesktop = (block) => {
    return (
      isWeddingPricing(block) ||
      isContactUsCard(block) ||
      isRecommendation(block)
    );
  };

  const hideInMobile = (block) => {
    return isContactUsCard(block) || isRecommendation(block);
  };

  return (
    <div className="space-y-24 my-24">
      <Container>
        <Grid>
          <div className="space-y-24 lg:col-span-7  lg:my-40 lg:space-y-40">
            <div className="lg:space-y-12 space-y-6">
              <h1 className="font-semibold text-h4 md:text-h3 text-gray-text">
                {weddingPackage.title}
              </h1>
              {weddingVenue && (
                <div className="flex items-center">
                  <Icon name="locate" fontSize="text-16" />
                  <span className="font-mulish text-12-22 tracking-2 text-gray-text pl-6">
                    {weddingVenue.title}
                  </span>
                </div>
              )}
            </div>

            {pricingBlock && (
              <div className="lg:hidden">
                <Pricing>
                  <PricingItem
                    label={t("Start from")}
                    price={weddingPackage.starting_price}
                    japaneseOnly={weddingPackage.japanese_only}
                    displayCurrency={weddingPackage.display_currency}
                  />
                </Pricing>
              </div>
            )}

            {nonPickedBlocks.map((block) => (
              <div
                className={cx({
                  "block lg:hidden": hideInDesktop(block),
                  "lg:block hidden": hideInMobile(block),
                })}>
                <RenderBlock
                  key={`${block.__typename}:${block.id}`}
                  block={{ ...block, weddingPackage }}
                />
              </div>
            ))}
          </div>

          <div className="my-24 space-y-24 lg:col-span-5  lg:my-40 lg:space-y-40 hidden lg:block">
            {pricingBlock && (
              <Pricing>
                <PricingItem
                  label={t("Start from")}
                  price={weddingPackage.starting_price}
                  japaneseOnly={weddingPackage.japanese_only}
                  displayCurrency={weddingPackage.display_currency}
                />
              </Pricing>
            )}
            {contactUsBlock && <RenderBlock block={contactUsBlock} />}
          </div>
        </Grid>
      </Container>

      {recommendations && (
        <Container>
          <RenderBlock block={recommendations} />
        </Container>
      )}

      {contactUsBlock && (
        <div className="lg:hidden">
          <Container>
            <RenderBlock block={contactUsBlock} />
          </Container>
        </div>
      )}
    </div>
  );
};
